import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue")
    },
    {
        path: "/imprint",
        name: "imprint",
        component: () => import("@/views/Imprint.vue")
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue")
    },
    {
        path: "/news",
        name: "news",
        component: () => import("@/views/News.vue")
    },
    {
        path: "/career",
        name: "career",
        component: () => import("@/views/Career.vue")
    },
    {
        path: "/newsletter/unsubscribe/:hash",
        name: "unsubscribeNewsletter",
        component: () => import("@/views/UnsubscribeNewsletter.vue")
    },
    {
        path: "/sale",
        name: "sale",
        component: () => import("@/views/Sale.vue")
    },
    {
        path: "/blog",
        name: "blog",
        component: () => import("@/views/Blog.vue")
    },
    {
        path: "*",
        redirect: { name: "home" }
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth"
            };
        }

        return { x: 0, y: 0 };
    }
});

export default router;
